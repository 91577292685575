<template>
  <div id="test">
    <el-row justify="space-around" type="flex">
      <el-col :xs="24" :sm="20" :md="16" :lg="12" :xl="10">
        <div style="position: relative; ">
          <Artplayer v-if="playType === 'inside'" :muted="false" ref="artplayer"
                     style="width: 100%;aspect-ratio: 16/9;"/>
          <iframe v-else ref="iframeTv" style="border: none;  top: 0;  width: 100%;aspect-ratio: 16/9;"
                  allowfullscreen="true"/>
        </div>
      </el-col>
    </el-row>

    <div style="margin: 10px 0px">
      <el-row>
        <el-col :span="4" style="text-align: center;vertical-align: middle;height:100%">
          <el-tag type="info" size="medium" style="height: 100%;width: 100%; line-height: 38px" @click="fullScreen">
            播放路线
          </el-tag>
        </el-col>
        <el-col :span="20">
          <el-select v-model="frontPlayAddreesName" placeholder="切换播放源" style="width: 99%" @change="changeSelePlay"
                     :disabled="this.orgPlayType != 1">
            <el-option
                v-for="item in frontPlayAddreesList"
                :key="item.name"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>

    <div>
      <div class="video_title">
        {{ titleName }} - {{episodeAllList.length}}集全
      </div>
    </div>

    <div>
      <section class="mod_box" dt-eid="module">
        <div class="mod_hd"><h2 class="mod_title">
          播放列表
        </h2></div>
        <div class="mod_subtitle U_color_c">
          <ul class="ul" id="tableList">
            <li class="li" v-for="(instance, index) in episodeList" :key="index"
                :ref="currentIndex === index ? 'activeRef' : ''"
                @click="play(instance, index)">
              <a :class="[currentIndex === index ? 'liChoose' : 'liNoChoose']"> <span class="text">
                {{ instance.title }} </span></a>
            </li>
          </ul>
        </div>
      </section>
    </div>

    <div v-if="$store.getters['systemConfig/getGGShow'] && isPhone">
      <i class="el-icon-circle-close" style="float: right; margin-top: 5px; font-size: 30px; "
         @click='$store.commit("systemConfig/closeGgShow", false)'></i>
      <iframe frameborder="no" style="pointer-events: none" src="https://file.charonv.com/GG.html" width="100%"
              height="100vh"></iframe>
    </div>
  </div>
</template>

<script>
import initialAllReq from "@/request/initialAllReq";
import PlayVideoReq from "@/request/PlayVideo";
import playRecord from "@/request/playRecord";
import expand from "@/request/expand";
import {isVideoPlayAddress} from "@/utils/validate";
import Artplayer from "../../components/Artplayer.vue";
import {isPhone} from "@/utils/validate";

export default {
  components: {Artplayer},
  name: "videoPlay",
  data() {
    return {
      HttpInitialAll: new initialAllReq(),
      askExpand: new expand(),
      playVideo: new PlayVideoReq(),
      askPr: new playRecord(),
      videoPlayUrl: '', //播放地址
      screen: '350', //视频高度
      episodeAllList: [], //全部列表
      episodeList: [], //列表
      titleName: '', //视频名称
      frontPlayAddreesName: '推荐播放源',
      frontPlayAddrees: 'http://okjx.cc/?url=', //当前播放前置地址
      frontPlayAddreesList: [], //播放前置地址集合
      tvType: 0,
      timer: null,
      currentIndex: 0, // 播放list下标
      dataPageNumber: 0, //当前页
      dataPageSize: 20, //每页显示条数
      dataPageTotal: 1, // 总页数
      orgPlayType: 1, //资源来源
      spiderId: 'this', //爬虫id
      playType: '', //播放器类型
      clickNextPage: false,
      listClientWidth: 0, //列表元素宽度
      listLastValue: 0, //列表滑动最后一次的值
      isPhone: false,
    }
  },
  mounted() {
    this.isPhone = isPhone()
    document.title = this.$t('title_') + this.$route.query.titleName
    this.spiderId = this.$route.query.spiderId
    this.tvType = this.$route.query.tvType
    this.playType = this.$route.query.playType
    const url = this.$route.query.id
    const title = this.$route.query.titleName
    this.listClientWidth = Math.ceil(document.getElementById("tableList").clientWidth);
    //加载播放源
    if (this.playType == 'analysis') {
      this.loadSourceInterface();
    }
    if (url == undefined || url == "") {
      this.videoPlayUrl = "http://m.v.qq.com/cover/m/mzc00200zbhf7m5.html?vid=c0036rl0hb7"
      this.titleName = "没有播放内容,默认播放."
    } else {
      this.titleName = title;
    }
    // 获取当前影视剧集
    if (this.spiderId == 'this') {
      this.episodeList = [{
        title: title,
        link: url
      }]
    } else {
      const data = {'charonVal': url, 'spiderId': this.spiderId};
      this.loadTvList(data, this.$route.query.tvPrChildId);
    }
    const this_ = this;
    if (this.$store.getters["user/getIsLogin"]) {
      this.timer = setInterval(() => {
        this_.askExpand.isLogin().then().catch(err => {
          console.log(err)
        })
      }, 1200000)
    }
  },
  methods: {
    /**
     * 加载影视续集
     * @param data 请求数据
     * @param tvPrChildId 历史播放集数
     * @returns {Promise<void>}
     */
    async loadTvList(data, tvPrChildId) {
      const loading = this.$loading({
        lock: true,
        text: '大橙拼命加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // 获取播放历史记录
      let prData = null;
      if (tvPrChildId) {
        prData = {
          prChildId: tvPrChildId
        }
      } else {
        // 获取播放记录
        if (this.$store.state.user.isLogin) {
          let res = await this.askPr.getPlayRecordById({url: data.charonVal})
          prData = res.msg;
        }
      }
      // 获取全部剧集
      this.HttpInitialAll.getGetShowList(data).then(result => {
        this.episodeAllList = result.msg;
        // 总页数
        this.dataPageTotal = parseInt((this.episodeAllList.length + this.dataPageSize) / this.dataPageSize)
        if (prData != null) {
          const indexI = this.episodeAllList.findIndex((profile) => profile.title == prData.prChildId);
          this.dataPageNumber = parseInt(indexI / this.dataPageSize) + 1;
          // 当前播放列表下标
          const currentIndexI = indexI % this.dataPageSize;
          this.currentIndex = this.dataPageNumber > 1 ? currentIndexI + 1 : currentIndexI;
        } else {
          this.dataPageNumber = 1;
        }
        loading.close();
      }).catch(err => {
        loading.close();
      })
    },
    /**
     * 加载播放源
     * @param link
     * @param title
     */
    loadSourceInterface() {
      this.playVideo.getPlayAddrees().then(result => {
        const res = result.msg;
        this.frontPlayAddreesList = res;
        this.frontPlayAddreesName = res[0].value
        this.frontPlayAddrees = res[0].value
      })
    },
    /**
     * 选集播放
     * @param link 播放地址
     * @param title
     * @param indexI
     */
    play(instance, indexI) {
      if (instance.link === 'nextPage') {
        this.dataPageNumber++;
        this.currentIndex = 1;
        this.clickNextPage = true
      } else if (instance.link === 'prevPage') {
        this.dataPageNumber--;
        this.currentIndex = this.dataPageNumber == 2 ? 20 : 19
        // return;
      } else if (instance.link === 'end') {
        return
      } else {
        this.currentIndex = indexI
        if (this.videoPlayUrl !== instance.link) {
          this.recordThePlay(instance.link, instance.title)
        }
        this.videoPlayUrl = instance.link;
        this.titleName = instance.title
        this.loadIframe();
      }
    },
    /**
     * 切换播放源
     * @param addrees
     */
    changeSelePlay(addrees) {
      this.frontPlayAddrees = addrees;
      this.loadIframe();
    },
    /**
     * 控制播放页的高
     */
    fullScreen() {
      // const isTrue = this.screen == window.innerHeight - 40;
      // if (isTrue) {
      //   this.screen = 350;
      // } else {
      //   this.screen = window.innerHeight - 40;
      // }
    },
    /**
     * 渲染iframe, 不记录路由
     * @param link
     */
    loadIframe() {
      let link = this.videoPlayUrl;
      // 解析
      if (this.playType === 'analysis') {
        link = this.frontPlayAddrees + this.videoPlayUrl;
      }
      // 外置播放器
      else if (this.playType === 'cPlayer') {
        if (isVideoPlayAddress(link)) {
          let videoUrl = link.split("//").find(isVideoPlayAddress);
          if (videoUrl != null) {
            link = this.$store.state.systemConfig.common.videoPlayerAdd + "https://" + videoUrl
          }
        }
      }
      // 内置播放器
      else if (this.playType === 'inside') {
        if (isVideoPlayAddress(link)) {
          let videoUrl = link.split("//").find(isVideoPlayAddress);
          if (videoUrl != null) {
            this.$refs.artplayer.changeUrl("https://" + videoUrl)
          }
        }
        return
      }
      this.$refs.iframeTv.contentWindow.location.replace(link);
    },


    /**
     * 记录播放记录
     */
    recordThePlay(link, title) {
      // 是否有剧集
      const isDrama = this.spiderId != 'this';
      const record = {
        prAddress: isDrama ? this.$route.query.id : link,
        prTitle: isDrama ? this.$route.query.titleName : title,
        prImg: this.$route.query.tvImg,
        prType: this.playType,
        prChildId: isDrama ? title : null,
        prOrgPlayType: this.spiderId
      }
      // 播放剧集更新url地址栏
      // window.location.href = `/videoPlay?id=${record.prAddress}&titleName=${record.prTitle}&tvImg=${record.prImg}&spiderId=${record.prOrgPlayType}&playType=${record.prType}&tvPrChildId=${record.prChildId}`
      // this.$router.push({
      //   name: 'videoPlay',
      //   query: {
      //     id: record.prAddress,
      //     titleName: record.prTitle,
      //     tvImg: record.prImg,
      //     spiderId: record.prOrgPlayType,
      //     playType: record.prType,
      //     tvPrChildId: record.prChildId
      //   }
      // })
      if (isDrama) {
        document.title = this.$t('title_') + this.$route.query.titleName + '-' + title
      }
      this.askPr.setPlayRecord(record);
    },
    /**
     * 往期列表滑动触发 => 剧集列表滑动分页
     */
    listScroll() {
      let scrollLeft = Math.ceil(document.getElementById("tableList").scrollLeft);
      console.log(scrollLeft)
      if (scrollLeft < 10 && scrollLeft < this.listLastValue) {
        console.log("上一页")
        this.dataPageNumber--
      }
      const maxScrollWidth = document.getElementById("tableList").scrollWidth - document.getElementById("tableList").clientWidth
      if ((maxScrollWidth - 10) < scrollLeft && scrollLeft > this.listLastValue) {
        console.log("下一页")
        this.dataPageNumber++
      }
      this.listLastValue = scrollLeft;
      // console.log(scrollLeft)
      // if (scrollLeft === 0 && this.dataPageNumber > 1 && !this.clickNextPage) {
      // if (this.episodeList.length < 9) {
      //   const isBo = this.episodeList.some((item) => item.link == 'prevPage');
      //   if (!isBo) {
      //     this.episodeList.unshift({
      //       "link": "prevPage",
      //       "title": "上一页",
      //     })
      //   }
      //   this.currentIndex = 1
      // } else {
      //   this.currentIndex = 19
      //   this.dataPageNumber--;
      // }
      // }
      // this.clickNextPage = false
    },
    /**
     * 计算当页数据
     */
    dataPaging(next) {
      // alert(this.dataPageTotal + '__' + this.dataPageNumber)
      this.currentIndex = 0;
      const e = document.getElementsByClassName('liChoose');
      if (e.length > 0) {
        e[0].scrollIntoView();
      }
    }
  }
  ,
  watch: {
    /**
     * 当前页数更新变动触发 => 定位显示到指定li集数
     */
    dataPageNumber(newItem, oldItem) {
      this.episodeList = this.episodeAllList.slice(newItem * this.dataPageSize - this.dataPageSize, this.dataPageSize * newItem);
      if (this.dataPageTotal > newItem) {
        const item = {
          "link": "nextPage",
          "title": "下一页",
        }
        this.episodeList.push(item);
      } else {
        const item = {
          "link": "end",
          "title": "完结",
        }
        this.episodeList.push(item);
      }
      if (newItem > 1) {
        this.episodeList.unshift({
          "link": "prevPage",
          "title": "上一页",
        })
      }
      this.$nextTick(function () {
        const e = document.getElementsByClassName('liChoose');
        if (e.length > 0) {
          e[0].scrollIntoView();
        }
      })
    }
  }
}
</script>

<style scoped>
body {
  margin: 0px;
}

.boder {
  border: none;
  /*pointer-events: none;*/
  /*opacity: 0.55;*/
  /*filter: alpha(opacity=40);*/
}

#test {
  width: 100%;
}

.mod_box {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  /*color: #e6e9f0;*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  padding: 14px 0;
  text-align: left;
}

.video_title {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 0;
  padding-left: 15px;
  /*color: #e6e9f0;*/
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  padding-top: 10px;
  overflow: hidden;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-overflow: ellipsis;
  margin: 0 0 5px;
  text-align: left;
}

.listCollection {
  width: 170px;
  height: 80px;
  /*background: */
  background-color: #e6e9f0;
  display: inline-block;
  text-align: center;
}

.listCollectionContent {
  text-align: justify;
}

.ul {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  color: #e6e9f0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  padding: 0 16px 15px;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 0;
  white-space: nowrap;
}

.li {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  color: #e6e9f0;
  list-style: none;
  font-size: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  vertical-align: top;
  -webkit-transform: translateZ(0);
  display: inline-block;
  position: relative;
  width: 170px;
  height: 80px;
  margin-right: 10px;
  border-radius: 2px;
  white-space: normal;
  background: hsla(0, 0%, 100%, .08);
}

.liChoose {
  background: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
}

.liNoChoose {
  background: #202131;
}

.li > a {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  list-style: none;
  white-space: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  display: -webkit-box;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  overflow: hidden;
  border-radius: 2px;
  font-size: 14px;
  line-height: 18px;
  vertical-align: middle;
  border-color: #f25b20;
  color: #f25b20;
}

.li > a > span {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  list-style: none;
  white-space: normal;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  color: #f25b20;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
}

.mod_hd {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  /*color: #e6e9f0;*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 0;
  display: flex;
  position: relative;
  margin: 0 15px 13px;
}

.mod_title {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /*color: #e6e9f0;*/
  flex: none;
  max-width: 60%;
  padding-right: 10px;
  overflow: hidden;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
