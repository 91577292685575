import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class initialAllReq {
    constructor() {
        this.http = new HttpHandler();
    }

    //搜索视频资源
    eachVideoReq(data) {
        let url = '/api/tvGet/getTvResources';
        return this.http.get(url, data);
    }

    //搜索视频资源
    getGetShowList(data) {
        let url = '/api/tvGet/getGetShowList';
        return this.http.get(url, data);
    }

    //api接口请求
    // 芒果tv接口请求
    mgTvInterfaceRequest(tvName) {
        const data = {'q': tvName}
        let url = '/mgtv/msite/search/v2';
        return this.http.get(url, data);
    }
}
