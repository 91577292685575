import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class PlayVideoReq {
    constructor() {
        this.http = new HttpHandler();
    }

    getPlayAddrees() {
        let url = '/api/video/wai/getPlayAddrees';
        return this.http.post(url, null);
    }

    getInterRes(path) {
        let url = `/api/${path}`;
        return this.http.get(url, null);
    }
}
