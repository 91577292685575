import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";

export default class playRecord {
    constructor() {
        this.http = new HttpHandler();
    }

    //登录
    getPlayRecordList(data) {
        let url = '/api/tvGet/getPlayRecord';
        return this.http.get(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    //记录播放记录
    setPlayRecord(data) {
        let url = '/api/tvGet/setPlayRecord';
        return this.http.post(url, data, HttpConstant.FORM_DATA_REQ);
    }

    /**
     * 根据url获取播放记录
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPlayRecordById(data) {
        let url = '/api/tvGet/getPlayRecordById';
        return this.http.get(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }
}